@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "UthmanTahaArabic";
  src: local("UthmanTahaArabic"), local("UthmanTahaArabic"),
    url("./assets/fonts/Uthman-Taha-Naskh-Regular.woff") format("woff"),
    url("./assets/fonts/Uthman-Taha-Naskh-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "UthmanTahaArabic";
  src: local("UthmanTahaArabic"), local("UthmanTahaArabic"),
    url("./assets/fonts/Uthman-Taha-Naskh-Bold.woff") format("woff"),
    url("./assets/fonts/Uthman-Taha-Naskh-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

*,
html {
  direction: rtl;
  font-family: "UthmanTahaArabic", "Times New Roman", Times, serif;
}

@layer base {
  :root {
    --color-primary: 253 198 8; /* #FDC608 */
    --color-secondary: 190 22 34; /* #be1622 */
    --color-background: 246 246 246; /* #F6F6F6 */
    --color-text: 1 1 1; /* #010101 */
    --navbar-height: 70px;
    /* ... */
  }
}
